.table th {
  background-color: var(--bs-white);
  border-top: none;
  font-weight: 600;
  color: var(--primary-blue) !important;
}

.table td {
  vertical-align: middle;
}

.table tbody tr:last-child td {
  border-bottom: none;
}

.table tbody tr:hover {
  background-color: var(--primary-blue) !important;
}

.table td.text-center {
  color: var(--primary-blue);
  font-weight: 500;
}